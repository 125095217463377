
import Vue from 'vue';

import { authProvider } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
interface IVueOptions extends InstanceType<typeof Vue> {
  authProvider: any;
  isUserSuperuser: boolean;
}
export default Vue.extend<IVueOptions>({ functional: false }).extend({
  metaInfo: {
    title: 'Organizations - '
  },
  name: 'Organizations',

  computed: {
    authProvider(): any {
      return authProvider(this.$store);
    },
    isUserSuperuser(): boolean {
      return this.authProvider.getCurrentUser.is_superuser;
    }
  },

  beforeRouteEnter(to, from, next) {
    next(async (vm: IVueOptions) => {
      if (!vm.isUserSuperuser) {
        // if user is not super user then route to login
        return next({
          name: 'sign-in'
        });
      }
    });
  }
});
